.SelectContainer {
    margin-top: .5rem;
    margin-bottom: -.5rem;
    .select-label {
      position: relative;
      margin-bottom: 0;
      font: normal 16px noah;
      color: #747474;
      opacity: .75;
      // @include respond(tiny-phone) {
      //   font-size: .8rem;
      // }
    }
    .custom-select {
      color: #747474;
      &:focus {
        border-color: $color-chhj-orange;
        box-shadow: 0 0 0 0.2rem rgba($color-chhj-orange, .25);
      }
    }
    #badReviewSelect {
      color: #747474;
      font: normal 16px/20px noah;
      opacity: .75;
    }

}