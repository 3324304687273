#SubHeader {
  position: relative;
  margin: auto;
  text-transform: uppercase;
  color: $color-text-gray;
  font-size: 16px;
  max-width: 30rem;
  .subheader-text {
    font-size: 1.4rem;
    @include respond(tab-port) {
      font-size: 1.3rem;
    }
    @include respond(phone) {
      font-size: 1rem;
    }
    @include respond(old-phone) {
      font-size: .9rem;
    }
  }
  .bold {
    font-weight: 700 !important;
  }
}
