.TextBox {
    textarea.form-control  {
        height: 100px;
    }
    .form-control:focus{
    border-color: $color-chhj-orange;
    box-shadow: 0 0 0 0.2rem rgba($color-chhj-orange, .25);
    }
    .text-box {
        font-family: noah;
    }
    .text-box::placeholder {
        color: #C7C7C7;
        font-family: noah;
        opacity: 1;
        letter-spacing: 0;

    }
}