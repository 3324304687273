#BadReview {
  overflow: auto;
  font-family: "rift soft";
  .BodyComponent {
    padding-top: 0rem !important;
    @include respond(tiny-phone) {
      margin-bottom: 6rem;
    }
  }
  .LargeTextContainer {
    margin-top: 0 !important;
    .large-text {
      font: lighter 30px/36px rift soft;
      // @include respond(tab-port) {
      //   font-size: 1.4rem;
      // }
      // @include respond(tiny-phone) {
      //   font-size: 1.2rem;
      // }
    }

    @include respond(tab-port) {
      margin-bottom: 1rem;
    }
  }
  .validation-message {
    text-transform: uppercase;
    color: #ff4444;
  }
  button {
    z-index: 1000;
    font: lighter 14px/17px rift soft;
    letter-spacing: 1.25px;
  }
  @include respond(tiny-phone) {
    .tighter-text {
      padding: 1rem;
      padding-top: 0;
    }
  }
 .loader-container {
   display: flex;
   justify-content: center;
 }
}

@media only screen and (min-height: 680px) {
  #BadReview {
    #Footer {
      position: fixed;
      bottom: 0;
    }
    #ImageFooter {
      position: fixed;
      right: 5%;
      bottom: 45px;
    }
  }
}