#NeutralReview {
  overflow: auto;
  font-family: rift soft;
  height: 100%;
  .validation-message {
    text-transform: uppercase;
    color: #ff4444;
  }
  button {
    z-index: 1000;
    font: lighter 14px/17px rift soft;
    letter-spacing: 1.25px;
  }
  .LargeTextContainer {
    @include respond(tiny-phone) {
      margin-top: 0.5rem;
    }
    .large-text {
      font: lighter 30px/36px rift soft;
      // @include respond(tab-port) {
      //   font-size: 1.4rem;
      // }
      // @include respond(tiny-phone) {
      //   font-size: 30px;
      // }
      // @include respond(old-phone) {
      //   font-size: 1rem;
      // }
    }
  }
  .BodyComponent {
    @include respond(tiny-phone) {
      margin-bottom: 6rem;
    }
  }
  @include respond(tiny-phone) {
    .tighter-text {
      padding: 1rem;
      padding-top: 0;
    }
  }
}

@media only screen and (min-height: 600px) {
  #NeutralReview {
    #Footer {
      position: fixed;
      bottom: 0;
    }
    #ImageFooter {
      position: fixed;
      right: 5%;
      bottom: 45px;
    }
  }
}