#ThreeFaces {
  .face-container {
    max-width: 30rem;
    margin: auto;
    background-image: url("~./Styles/Assets/img/trip-150x107-1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18rem;
    @include respond(tiny-phone) {
      background-size: 15rem;
      margin: 0;
    }

    &-words {
        max-width: 30rem;
        margin: auto;
        color: $color-text-gray;
        text-transform: uppercase;
        font-size: 16px;
    }
  }
  .question-wrapper {
    @include respond(old-phone) {
      padding-top: 0 !important;
    }
  }
  .under-face-text {
    font-size: .8rem;
    margin-top: .5rem;

    
    @include respond(old-phone) {
      font-size: .7rem;
    }
  }
  img {
    height: 4rem;
    &:hover {
      cursor: pointer;
    }
  }
  .exp-text {
    font-size: 1.8rem;
    color: $color-text-gray;
    margin-bottom: 1rem;

    @include respond(old-phone) {
      font-size: 1.4rem;
    }
  }


}
