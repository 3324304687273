.LargeTextContainer {
  margin-top: 1rem;
  
  .large-text {
    font-size: 1rem;
    color: $color-text-gray;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0 !important;
  }
}
