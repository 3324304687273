// this file exists a single mass import of various styles and libraries, this is were we would force conflicting styles to play nice

@import "./Styles";
// @import url("https://hunkware.s3.amazonaws.com/fonts/css/rift.css"),
//   url("https://hunkware.s3.amazonaws.com/fonts/css/noah.css");

.hide {
  display: none !important;
}
body {
  position: relative;
  font-family: "rift soft", sans-serif;
  min-height: 100%;
  overflow: auto;
  background-color: #fff;
}

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  // iphone 11
  body {
    min-height: calc(100vh - 115px);
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  // iphone 11 pro
  body {
    min-height: calc(100vh - 115px);
  }
}
@media only screen and (min-device-width: 375px) and (device-height: 896px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  // iphone 11 pro max
  body {
    min-height: calc(100vh - 115px);
  }
}
