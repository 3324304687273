#Header {
  height: 6rem;
  padding-right: 0 !important;
  margin: 0 !important;
  .headerlogo {
    max-height: 65px;
  }
  .client {
    &-container {
      background: transparent url("~./Styles/Assets/img/tape.png") 0% 0% no-repeat padding-box;
      text-align: center;
      opacity: 1;
      @include respond(tiny-phone) {
        background: transparent url("~./Styles/Assets/img/tape.png") 0% 0% no-repeat padding-box;
        background-size: auto;
        margin-left: 0 !important;
        max-width: 175px;
      }
    }
    &-text {
      white-space: nowrap;
      height: 100%;
      width: 100%;
      color: $color-true-white;
      font-weight: 300 !important;
      font-size: 1.6rem;
      padding-top: 0.5rem;
      text-transform: uppercase;
      vertical-align: center;
      &-small {
        padding-top: 1rem;
        color: $color-true-white;
        font-size: 1.2rem;
      }
      @include respond(phone){
        font-size: calc(4vw);
        padding-top: calc(4vw);
      }
    }
  }
}
