#GoodReview {
  overflow: auto;
  font-family: "rift soft";

  .reminder-text {
    margin-top: 0.5rem;
    text-align: center;
    text-transform: uppercase;
    opacity: 0.7;

    @include respond(tab-port) {
      font-size: 1.1rem;
      margin-top: 1rem;
      padding-bottom: 1rem;
    }
    @include respond(phone) {
      font-size: 1rem;
      margin-top: 0.5rem;
    }
    @include respond(tiny-phone) {
      font-size: 14px;
      margin-top: 0;
    }
    @include respond(old-phone) {
      font-size: 0.9rem;
    }
  }
  .review-link-container {
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
    max-height: 40px;
    box-shadow: 0px 3px 6px #00000029;
  }
  .review-logo-img {
    max-height: 24px;
  }
  .logo-text {
    color: #ffffff;
    font: 500 17px/21px rift soft;
    font-weight: 500;
  }
  .link-container {
    display: flex;
    justify-content: center;
  }

  .LargeTextContainer {
    @include respond(tiny-phone) {
      margin-top: 0.5rem;
    }
    .large-text {
      font: lighter 30px/36px rift soft;
      // @include respond(tab-port) {
      //   font-size: 1.4rem;
      // }
      // @include respond(tiny-phone) {
      //   font-size: 30px;
      // }
      // @include respond(old-phone) {
      //   font-size: 1rem;
      // }
    }
  }
  .BodyComponent {
    @include respond(tiny-phone) {
      margin-bottom: 10rem;
    }
  }
}

@media only screen and (min-height: 680px) {
  #GoodReview {
    #Footer {
      position: fixed;
      bottom: 0;
    }
    #ImageFooter {
      position: fixed;
      right: 5%;
      bottom: 45px;
    }
  }
}
