@mixin respond($breakpoint) {
  @if $breakpoint == old-phone {
    @media (max-width: 320px) {
      @content;
    }
  }
  // tiny
  @if $breakpoint == tiny-phone {
    @media (max-width: 420px) {
      @content;
    }
  }
  // extra small
  @if $breakpoint == phone {
    @media (max-width: 576px) {
      @content;
    }
  }
  // landscape phone/ portrat tablets (small)
  @if $breakpoint == tab-port {
    @media (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == middle-ground {
    @media (max-width: 992px) {
      @content;
    }
  }
  // tablets
  @if $breakpoint == tab-land {
    @media (max-width: 1200px) {
      @content;
    }
  }
  // 1080p+ screens
  @if $breakpoint == desktop {
    @media (min-width: 1200.1px) {
      @content;
    }
  }
  // 1600+ is default
}
