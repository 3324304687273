#Confirmation {
  font-family: "rift soft";
  .LargeTextContainer {
    .large-text {
      opacity: 0.9;
      font-size: 2rem;
      @include respond(tab-port) {
        font-size: 1.8rem;
      }
      @include respond(phone) {
        font-size: 30px;
      }
    }
  }
  .confirmation-var-text {
    text-transform: uppercase;
    text-align: center;
    margin-top: 1rem;
    font-size: 20px;
    @include respond(tab-port) {
      font-size: 17px;
    }

    @include respond(phone) {
      font-size: 14px;

      opacity: 0.7;
    }
  }
}

@media only screen and (min-height: 380px) {
  #Confirmation {
    #Footer {
      position: fixed;
      bottom: 0;
    }
    #ImageFooter {
      position: fixed;
      right: 5%;
      bottom: 45px;
    }
  }
}