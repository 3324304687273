#Footer {
  box-sizing: border-box;
  background-color: $color-chhj-orange;
  bottom: 0;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  height: 38px;
  .footer {
    &-text {
      font-size: 0.9rem;
      font-weight: 300;
      text-transform: uppercase;
      color: $color-true-white;
      opacity: 0.6;

      @include respond(old-phone) {
        font-size: 13px;
      }
    }
  }
}

#ImageFooter {
  margin-left: 0;
  margin-right: 0;
  max-height: 88px;
  margin-top: 10px;
  margin-bottom: 10px;
  img {
    max-width: calc(80vw);
  }
}
