#Home {
  // font-family: 'RiftSoft';
  // font-weight: 300;
  font-family: 'rift soft';
  // height: 100vh;
  #SubHeader {
    margin-top: 1.5rem;
    padding-left: 1rem;

    @include respond(tiny-phone) {
      margin-top: 0;
    }
  }
  #ThreeFaces {
    margin-top: 1rem;
  }
  .BodyComponent {
    @include respond(tiny-phone) {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }

  }
}

@media only screen and (min-height: 550px) {
  #Home {
    #Footer {
      position: fixed;
      bottom: 0;
    }
    #ImageFooter {
      position: fixed;
      right: 5%;
      bottom: 45px;
    }
  }
}