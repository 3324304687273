.BodyComponent {
  overflow: auto;
  margin: auto;
  max-width: 30rem;

  @include respond(tiny-phone) {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }
}
